import React from "react"
import { Box, Container, Flex, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"

import { colorModes } from "@const"
import SEO from "@components/seo"
import Layout from "@layouts/default"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout colorMode={colorModes.light}>
      <SEO title="FAQ" />
      <Flex as="section" variant="layout.faq.container">
        <Container variant="layout.flexColumn">
          <Flex variant="layout.faq.header">
            <Flex>
              <Box variant="layout.faq.bubble">?</Box>
              <Box>
                <Styled.h1>{frontmatter.title}</Styled.h1>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Flex>
      <Container as="section">
        <Flex variant="layout.faq.content">
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Flex>
      </Container>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`
